var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _c("header", { staticClass: "header header--borderBottom" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _vm._m(0),
        _vm.hasEliteSubscription
          ? _c("div", { staticClass: "headerContainer__alignRight" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn--sm btn--black btn--withIconLeft",
                  on: { click: _vm.openSetPasswordModal },
                },
                [
                  _c("icon", {
                    attrs: { name: "key", width: "14", height: "14" },
                  }),
                  _vm._v(_vm._s(_vm.passwordButtonText)),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "section" },
      [
        _vm.isLoading || _vm.beats.length === 0
          ? _c(
              "div",
              { staticClass: "container container--fullWidth" },
              [_c("VLoadSpinner")],
              1
            )
          : !_vm.hasEliteSubscription
          ? _c("AlertBoxSubscription")
          : _c(
              "div",
              { staticClass: "container container--fullWidth wrapper--filled" },
              [
                _c("table", [
                  _vm._m(1),
                  _c(
                    "tbody",
                    _vm._l(_vm.beats, function (beat) {
                      return _c(
                        "tr",
                        {
                          key: beat.id,
                          staticClass: "beat-table",
                          attrs: { beat: beat },
                        },
                        [
                          _c("td", { staticClass: "beat-table__image" }, [
                            _c("img", {
                              staticClass: "beat-table__image",
                              attrs: {
                                src: beat.image_url
                                  ? beat.image_url
                                  : "./img/picture.svg",
                              },
                            }),
                          ]),
                          _c(
                            "td",
                            { staticClass: "beat-table__title" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "BeatEdit",
                                      params: { id: Number(beat.id) },
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(beat.name))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "beat-table__edit" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn--sm btn--black btn--text",
                                  staticStyle: { padding: "0" },
                                  attrs: {
                                    to: {
                                      name: "BeatEdit",
                                      params: { id: Number(beat.id) },
                                    },
                                  },
                                },
                                [_vm._v("EDIT")]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headerContainer__heading" }, [
      _c("h3", [_vm._v("Beats")]),
      _c("h1", [_vm._v("Private Vault")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "beat-table" }, [
        _c("th", { staticClass: "beat-table__image", attrs: { width: "70" } }),
        _c("th", [_vm._v("BEAT NAME")]),
        _c("th", { staticClass: "beat-table__edit", attrs: { width: "120" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }